.PageHeader .navbar {
    opacity: 0.65;
}

svg#config {
    height: auto;    
    width: 1.5rem; 
}

svg#logo {
    height: auto;
    margin-right: 0.25em;
    width: 1.5rem; 
}