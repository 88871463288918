div.Carousel {
  align-content: center;
  display: flex;
  gap: 2em;
  height: 1em;
  justify-content: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

div.Carousel input[type="radio"] {
  cursor: pointer;
}
