div.GlCiBoard {
  display: table;
  height: calc(100% - 6em);
  padding-top: 1em;
  padding-bottom: 2em;
  padding-left: 2em;
  padding-right: 2em;
  width: 100%;
}

div.GlCiBoardRow {
    display: table-row;
}
